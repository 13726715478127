(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/client/create-jupiter-instance.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/client/create-jupiter-instance.js');
'use strict';

const {
  jupiterFetch
} = svs.core;
const createJupiterInstance = () => async function () {
  const response = await jupiterFetch.fetch(...arguments);
  const data = await response.json();
  if (!response.ok) {
    var _data$error;
    const errorMsg = (data === null || data === void 0 || (_data$error = data.error) === null || _data$error === void 0 ? void 0 : _data$error.message) || 'Error occurred while fetching';
    throw new Error(errorMsg);
  }
  return data;
};
setGlobal('svs.components.marketplaceCompetition.competitionHandler.client.createJupiterInstance', createJupiterInstance);

 })(window);