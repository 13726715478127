(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/client/create-competition-handler.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/client/create-competition-handler.js');
'use strict';

var _svs$competition$data, _svs, _svs$competition$data2, _svs2, _svs$competition$data3, _svs3, _svs$competition$data4, _svs4, _svs5;
const {
  createCompetitionModel,
  createCompetitionHandlerBase,
  createPlayerCompetitionHandlerBase,
  functional: {
    pipe
  }
} = svs.components.marketplaceCompetition.competitionHandler.common;
const {
  createJupiterInstance
} = svs.components.marketplaceCompetition.competitionHandler.client;
const {
  drawHandler
} = svs.components.sport.drawHandler;
const competitionsData = (_svs$competition$data = (_svs = svs) === null || _svs === void 0 || (_svs = _svs.competition) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 || (_svs = _svs.competitions) === null || _svs === void 0 ? void 0 : _svs.competitions) !== null && _svs$competition$data !== void 0 ? _svs$competition$data : {};
const activeCompetitionIds = (_svs$competition$data2 = (_svs2 = svs) === null || _svs2 === void 0 || (_svs2 = _svs2.competition) === null || _svs2 === void 0 || (_svs2 = _svs2.data) === null || _svs2 === void 0 || (_svs2 = _svs2.competitions) === null || _svs2 === void 0 ? void 0 : _svs2.activeCompetitionIds) !== null && _svs$competition$data2 !== void 0 ? _svs$competition$data2 : [];
const playerCompetitions = (_svs$competition$data3 = (_svs3 = svs) === null || _svs3 === void 0 || (_svs3 = _svs3.competition) === null || _svs3 === void 0 || (_svs3 = _svs3.data) === null || _svs3 === void 0 || (_svs3 = _svs3.playerDetail) === null || _svs3 === void 0 ? void 0 : _svs3.playerCompetitions) !== null && _svs$competition$data3 !== void 0 ? _svs$competition$data3 : {};
const playerCompetitionDraws = (_svs$competition$data4 = (_svs4 = svs) === null || _svs4 === void 0 || (_svs4 = _svs4.competition) === null || _svs4 === void 0 || (_svs4 = _svs4.data) === null || _svs4 === void 0 || (_svs4 = _svs4.playerDetail) === null || _svs4 === void 0 ? void 0 : _svs4.playerCompetitionDraws) !== null && _svs$competition$data4 !== void 0 ? _svs$competition$data4 : {};
const playerCompetitionFetchStatus = (_svs5 = svs) === null || _svs5 === void 0 || (_svs5 = _svs5.competition) === null || _svs5 === void 0 || (_svs5 = _svs5.data) === null || _svs5 === void 0 || (_svs5 = _svs5.playerDetail) === null || _svs5 === void 0 ? void 0 : _svs5.status;
const {
  onEvent,
  eventNames
} = svs.components.lbUtils.onEvents;
const jupiter = createJupiterInstance();
const createCompetitionHandler = function () {
  let privateInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  privateInterface.setPlayerCompetitionDetails({
    playerCompetitions,
    playerCompetitionDraws,
    status: playerCompetitionFetchStatus
  });
  onEvent(eventNames.PAYMENT_SUCCESS, _ref => {
    var _payable$isCompetitio;
    let {
      detail: {
        payable
      }
    } = _ref;
    if ((_payable$isCompetitio = payable.isCompetition) !== null && _payable$isCompetitio !== void 0 && _payable$isCompetitio.call(payable)) {
      const bet = payable.getCompetitionBet();
      const competitionId = payable.competitionId[0];
      privateInterface.setPlayerCompetitionDraw({
        drawNumber: bet.drawNumber,
        competitionId
      });
    }
  });
  onEvent(eventNames.COMPETITION_SIGN_UP, _ref2 => {
    let {
      detail: {
        competitionId
      }
    } = _ref2;
    privateInterface.setPlayerCompetition({
      competitionId
    });
  });
  onEvent(eventNames.PAYMENT_CANCEL, _ref3 => {
    let {
      detail: {
        wager
      }
    } = _ref3;
    if (wager.competitionId) {
      const competitionId = wager.competitionId;
      privateInterface.deletePlayerCompetitionDraw({
        drawNumber: wager.drawNumber,
        competitionId
      });
    }
  });
  return function () {
    let publicInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    for (const [, competitionData] of Object.entries(competitionsData)) {
      const {
        productId,
        currentDrawNumber: drawNumber
      } = competitionData.model.details;
      const draw = drawHandler().get(productId, drawNumber);
      const competition = createCompetitionModel(competitionData, draw);
      privateInterface.competitionCache.setModel(competition);
    }
    privateInterface.setCmsCompetitionIds(activeCompetitionIds);


    publicInterface.getPlayerCompetitionDetails = () => JSON.parse(JSON.stringify(privateInterface.getPlayerCompetitionDetails()));
    return publicInterface;
  };
};

const privateInterface = {
  jupiter
};
const fns = [createCompetitionHandlerBase, createPlayerCompetitionHandlerBase, createCompetitionHandler].map(fn => fn(privateInterface));
const competitionHandlerCreator = pipe(...fns);
setGlobal('svs.components.marketplaceCompetition.competitionHandler.client.createCompetitionHandler', competitionHandlerCreator);

 })(window);